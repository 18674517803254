<template>
  <section class="container flex gap-16">
    <div class="text-left lg:w-1/2">
      <Badge
        label="Services"
        background-color="bg-green-50"
        border-color="border-green-600"
        text-color="text-green-600" />
      <h2 class="mt-4 text-4xl uppercase text-green-500 md:text-6xl">
        Instant online booking for all your moving needs.
      </h2>
      <div class="mt-8 flex flex-col gap-4">
        <Accordion
          title="Delivery & Small Moves"
          :active="activeTab === 'delivery'"
          @click="activeTab = 'delivery'">
          <p>
            Whether it's a single item or a small apartment move, you’ll be instantly connected with our highly rated fleet of haulers who will ensure your
            items are transported safely and swiftly in a ute or cargo van. Experience the ease of moving with PicUp!
          </p>
          <NuxtLink to="/bookings">
            <Button class="mt-4">
              Book Now
            </Button>
          </NuxtLink>
        </Accordion>

        <Accordion
          title="Junk & Rubbish Removal"
          :active="activeTab === 'junk-removal'"
          @click="activeTab = 'junk-removal'">
          <p>
            Ready to declutter? Transform your space with our efficient junk removal service. We make disposal hassle-free, so you can enjoy a cleaner, more
            organised home or office.
          </p>
          <NuxtLink to="/bookings">
            <Button class="mt-4">
              Book Now
            </Button>
          </NuxtLink>
        </Accordion>

        <Accordion
          title="Apartment, Unit & Townhouse Removals"
          :active="activeTab === 'removal'"
          @click="activeTab = 'removal'">
          <p>
            Unlock the ultimate stress-free moving solution with PicUp's dedicated truck removals service specifically designed for Apartment, Unit and
            Townhouse residents.
          </p>
          <NuxtLink to="/bookings">
            <Button class="mt-4">
              Book Now
            </Button>
          </NuxtLink>
        </Accordion>
      </div>
    </div>
    <div class="w-1/2 max-lg:hidden">
      <NuxtImg
        src="/images/hauler-one.jpg"
        sizes="90vw md:800px"
        densities="1x, 2x"
        fit="cover"
        quality="85"
        loading="lazy"
        class="z-10 w-full rounded-xl"
        alt="hauler" />
    </div>
  </section>
</template>

<script setup lang="ts">
const activeTab = ref('delivery')
</script>
